.contact-form-section {
    width: 100%;
    margin: 6rem auto;
    max-width: 32rem;
    color: map-get($map: $theme-colors, $key: 'black');

    .form {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        .message-container, .button-container, .email-container {
            margin-top: 1.5rem;

            textarea, input {
                width: 100%;
                background-color: transparent;
            }

            textarea {
                min-height: 12rem;
            }
        }
    }
}

@media screen and (max-width: $screen-sm) {
    .contact-form-section {
        width: 90%;
    }
}

