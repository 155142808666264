.nav-item {
    list-style: none;
    margin-top: 0.25rem;
    
    .nav-link{
        text-decoration: none;
        color: map-get($map: $theme-colors, $key: 'grey-100');
        &:hover {
            color: map-get($map: $theme-colors, $key: 'blue-100');
        }
        &:focus {
            outline: 2px dashed map-get($map: $theme-colors, $key: 'black');
            outline-offset: 0.5rem;
        }
    }
}

.nav-item.active {
    .nav-link{
        color: map-get($map: $theme-colors, $key: 'blue');
    }  
}
