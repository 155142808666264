h1{
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: bold;
    font-size: 7rem;
}
h2{
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: bold;
    font-size: 4rem;
}
h3{
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
}
h4{
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
}
h5{
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
}
h6{
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: bold;
    font-size: 0.8rem;
}

.body1 {
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem; 
}
.body2 {
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
}
.body3 {
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
}
.body4 {
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
}
.body5 {
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
}
.body6 {
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
}
label {
    font-family: 'BL Melody';
    font-style: normal;
    font-weight: bold;
    font-size: 0.8rem;
}