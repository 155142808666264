.about {
    .art-container {
        position: relative;
        display: flex;
        justify-content: center;

        .kg-image {
            z-index: 99;
        }

        .circle-name-container {
            position: absolute;
            margin-left: -5rem;
            margin-top: 2rem;

            width: 22rem;
            height: 22rem;
            border-radius: 50%;
            background-color: map-get($map: $theme-colors, $key: 'green');
            display: flex;
            justify-content: center;
            align-items: center;

            .kishor {
                margin-left: -28rem;
                margin-top: -3rem;
                color: map-get($map: $theme-colors, $key: 'blue');
            }
        }
        .rectangle {
            position: absolute;
            z-index: 99;
            height: 4rem;
            width: 28rem;
            display: flex;
            justify-content: center;
            margin-top: 24rem;
            margin-left: 30rem;
            background-color: map-get($map: $theme-colors, $key: 'orange');
            
            .ghising {
                margin-top: -3.5rem;
                color: map-get($map: $theme-colors, $key: 'blue');
            }
        }
    }

    .content-container {
        max-width: 56rem;
        margin: auto;
        display: flex;
        justify-content: center;

        .content > * {
            margin-top: 1.5rem;
        }
    }
}

@media screen and (max-width: $screen-md) {
    .content-container {
        width: 90%;
    }
}

@media screen and (max-width: $screen-sm) {
    .about {
        .content-container {
            width: 90%;
        }
        .art-container {
            .circle-name-container {
                .kishor {
                    margin-left: -12rem;
                    margin-top: -3rem;
                    font-size: 5rem;
                    color: map-get($map: $theme-colors, $key: 'blue');
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke-width: 2px;
                    -webkit-text-stroke-color: map-get($map: $theme-colors, $key: 'black');
                    z-index: 99;
                }
            }
            .rectangle {
                height: 3.5rem;
                width: 24rem;
                margin-left: 12rem;
                
                .ghising {
                    font-size: 5rem;
                    margin-top: -2rem;
                    color: map-get($map: $theme-colors, $key: 'blue');
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke-width: 2px;
                    -webkit-text-stroke-color: map-get($map: $theme-colors, $key: 'black');
                }
            }
        }
    }
}

@media screen and (max-width: $screen-xs) {

    .about {
        .art-container {
            .circle-name-container {
                .kishor {
                    display: none;
                }
            }
            .rectangle {
                position: absolute;
                height: 3rem;
                width: 20rem;
                margin-left: 8rem;
                
                .ghising {
                    display: none;
                }
            }
        }
    }
}