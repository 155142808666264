.card-link {
    color: inherit;
    text-decoration: inherit;
}

.thumbnail-card {
    width: 100%;
    height: 24rem;
    padding: 1rem;
    overflow: hidden;
    border-radius: 0.25rem;
    display: flex;
    align-items: flex-end;
    border: 1px solid map-get($map: $theme-colors, $key: 'grey-200');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);

    .thumbnail-card-content {
        margin-bottom: -2rem;
        color: map-get($map: $theme-colors, $key: 'grey');
    }
}

.thumbnail-card:hover {
    box-shadow: 3px 3px map-get($map: $theme-colors, $key: 'blue');
    border: 1px solid map-get($map: $theme-colors, $key: 'blue');
    filter: grayscale(0%);

    .thumbnail-card-content {
        margin-bottom: 0;
    }
}

.card-link:focus {
    cursor: progress;
    outline: 2px dashed map-get($map: $theme-colors, $key: 'black');
    outline-offset: 0.5rem;
}