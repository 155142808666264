.button {
    background-color: map-get($map: $theme-colors, $key: 'blue-100');
    color: map-get($map: $theme-colors, $key: 'white');
    border-radius: 0.125rem;
    border: none;
    padding: 0.5rem 1rem;
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    text-decoration: none;

    &:hover {
        background-color: map-get($map: $theme-colors, $key: 'blue');
        cursor: pointer;
    }
    
    &:focus {
        background-color: map-get($map: $theme-colors, $key: 'blue');
        cursor: progress;
        outline: 2px dashed map-get($map: $theme-colors, $key: 'black');
        outline-offset: 0.5rem;
    }
}