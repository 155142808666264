$theme-colors: (
  'black': #171D1C,
  'white': #fcfcfc,
  'grey': #4E4F54,
  'grey-100': #95969D,
  'grey-200': #CACBCE,
  'grey-300': #F2F2F3,
  'blue': #4747EB,
  'blue-100': #7575F0,
  'blue-200': #E8E8FC,
  'green': #1FAD4E,
  'green-100': #66CC88,
  'green-200': #DEEDE3,
  'red': #EC431D,
  'red-100': #EA846E,
  'red-200': #F2DDD9,
  'orange': #ECB21D,
  'orange-10': #EAC76E,
  'orange-200': #F1EBDA,
);
