.input-field {
    margin-top: 0.5rem;
    width: 30rem;
    height: 2.5rem;
    border-radius: 0.25rem;
    padding: 0 0.75rem;
    border: 1px solid map-get($map: $theme-colors, $key: 'grey-200');

    &:focus {
        outline: none;
        border: 1px solid map-get($map: $theme-colors, $key: 'blue');
    }
}

.text-area {
    margin-top: 0.5rem;
    width: 30rem;
    height: 7rem;

    border-radius: 0.25rem;
    padding: 0.75rem 0.75rem;
    border: 1px solid map-get($map: $theme-colors, $key: 'grey-200');

    &:focus {
        outline: none;
        border: 1px solid map-get($map: $theme-colors, $key: 'blue');
    }
}