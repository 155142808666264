.back-button-container {
    width: 100%;
    max-width: 75rem;
    margin: auto;

    &:hover {
        cursor: pointer;
    }

    .back-button {
        display: flex;
        width: min-content;
        column-gap: 0.5rem;
        align-items: center;
        text-decoration: none;
        background-color: map-get($map: $theme-colors, $key: 'blue-200');
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        position: fixed;


        label, .left-icon {
            color: map-get($map: $theme-colors, $key: 'blue-100');
        }

        &:hover {
            label, .left-icon {
                cursor: pointer;
                color: map-get($map: $theme-colors, $key: 'blue');
            }
        }
        &:focus {
            outline: 2px dashed map-get($map: $theme-colors, $key: 'black');
            outline-offset: 0.5rem;
        }
    }
}

@media screen and (max-width: $screen-md) {
    .back-button-container {
        width: 90%;
    }
}

@media screen and (max-width: $screen-sm) {
    .back-button label {
        display: none;
    }
}

.project-details {
    min-height: 70vh;
}

.design-img {
    width: 100%;
    height: auto;
}
