@import 'Theme';
@import 'Screen';
@import 'TypeStyle';
@import 'Topbar';
@import 'NavLink';
@import 'Navigations';
@import 'BottomBar';
@import 'SocialLink';
@import 'ThumbnailCard';
@import 'Form';
@import 'Button';
@import 'Project';
@import 'Contact';
@import 'About';
@import 'ProjectDetails';
@import 'Blog';
@import 'Home';
@import 'ShotPage';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    font-family: 'BL Melody';
    font-size: 16px;
    margin:0;
    padding:0;
    overflow-x:hidden;
    background-color: map-get($map: $theme-colors, $key: 'white');
}
.App {
    .top-navbar-container {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
        background-color: map-get($map: $theme-colors, $key: 'white');
    }

    .navbar-container {
        position: -webkit-sticky;
	    position: sticky;
        top: 0;
    }

    .navbar-container, .bottom-bar-container {
        background-color: map-get($map: $theme-colors, $key: 'grey-300');
        border-top: 1px solid map-get($map: $theme-colors, $key: 'grey-200');
        border-bottom: 1px solid map-get($map: $theme-colors, $key: 'grey-200');
    }

    .main-section {
        width: 100%;
        margin: auto;
        min-height: 80vh;
        margin-top: 5rem;
        margin-bottom: 2rem;
    }

    .comming-soon-heading {
        text-align: center;
    }
}
