.navbar {
    width: 90%;
    max-width: 64rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    column-gap: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.75rem;

    .sup {
        font-size: 0.75rem;
        font-weight: bold;
        color: map-get($map: $theme-colors, $key: 'blue');
        text-transform: uppercase;
        margin-left: 0.2rem;
    }
}
