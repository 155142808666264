.bottom-bar {
    width: 90%;
    max-width: 75rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    gap: 1rem;

    p {
        color: map-get($map: $theme-colors, $key: 'grey');
        width: fit-content;
    }
}

@media screen and (max-width: $screen-sm) {
    .bottom-bar {
        flex-direction: column-reverse;
        text-align: center;
        gap: 1rem;
    }
}