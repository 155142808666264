.home {
    width: 90%;
    max-width: 64rem;
    margin: auto;

    .about-hero {
        display: flex;
        align-items: center;
        column-gap: 5rem;
        padding: 3rem 0;

        .kishor-image {
            width: 20rem;
            filter: grayscale(100%);
        }

        .about-me {
            color: map-get($map: $theme-colors, $key: 'black');
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
        }
    }

    .shots-container {
        margin-top: 6rem;
        color: map-get($map: $theme-colors, $key: 'black');

        .shots::-webkit-scrollbar { 
            display: none;
        }
        .shots {
            display: flex;
            column-gap: 1rem;
            margin-top: 1.5rem;
            overflow-x: scroll;

            -ms-overflow-style: none;            
            scrollbar-width: none;

            .shot-link {
                width: 100%;
                text-decoration: none;
            }

            .image-container {
                width: 100%;
                min-width: 12rem;
                aspect-ratio: 1/1;
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                padding: 1rem;
                border-radius: 0.25rem;
                display: flex;
                align-items: flex-end;
                overflow: hidden;
                position: relative;

                h5 {
                    color: map-get($map: $theme-colors, $key: 'white');
                    margin-bottom: -4rem;
                }
            }

            .image-container:hover {
                background-size: 110%;
                transition: 0.5s;
                cursor: pointer;

                h5 {
                    margin-bottom: 0rem;
                    z-index: 1;
                }
                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    display: block;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0,0,0,0.3);
                }
            }
        }
    }

    .project-container {
        margin-top: 6rem;
        color: map-get($map: $theme-colors, $key: 'black');
    }

    .blogs-container {
        margin-top: 6rem;
        color: map-get($map: $theme-colors, $key: 'black');
    }

    .about-container {
        margin-top: 6rem;
        color: map-get($map: $theme-colors, $key: 'black');
        .text-container {
            margin-top: 1.5rem;
        }
    }

    .contact-container {
        margin-top: 6rem;
        color: map-get($map: $theme-colors, $key: 'black');
        
        p {
            margin-top: 1.5rem;
            color: map-get($map: $theme-colors, $key: 'grey');
        }
    }
}

@media screen and (max-width: $screen-sm) {
    .about-hero {
        flex-direction: column-reverse;
        row-gap: 3rem;
    }
}
