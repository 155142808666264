.projects {
    width: 100%;
    max-width: 64rem;
    margin: auto;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 1rem;
    margin-top: 1.5rem;
}

@media screen and (max-width: $screen-md) {
    .projects {
        width: 100%;
    }
}

@media screen and (max-width: $screen-sm) {
    .projects {
        grid-template-columns: auto;
    }
}